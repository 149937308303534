import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios'
import type {
  APIData,
  APIDeleteRequest,
  APIGetRequest,
  APIHeaders,
  APIParams,
  APIPatchRequest,
  APIPostRequest,
  APIPutRequest,
} from '@ancon/wildcat-types'
import {
  getEndpointWithVersion,
  getUrlAndParams,
} from '@ancon/wildcat-utils/api'
import { type User } from 'firebase/auth'
import { v4 } from 'uuid'

import { APIType } from '../types'
import firebaseAuth from '../../features/firebase/firebaseAuth'
import getOneSignalAppId from '../../features/app/utils/getOneSignalAppId'
import getLocaleTag from '../../features/app/utils/getLocalTag'
import handleResponseRejection from '../interceptors/handleResponseRejection'
import handleResponse from '../interceptors/handleResponse'
import config from '../../features/config/generated.json'

const defaultHeaders: Partial<RawAxiosRequestHeaders> = {
  'Client-Version': process.env.NEXT_PUBLIC_APP_VERSION,
  Client: 'ancon order web',
  oneSignalAppId: getOneSignalAppId(),
  ...(config.headers ?? null),
}

function createAxiosClient(
  baseUrl: string,
  options: {
    intercept?: boolean
    defaultHeaders?: RawAxiosRequestHeaders
    defaultParams?: Record<string, unknown>
  } = {},
) {
  const client = axios.create({
    baseURL: baseUrl,
    headers: options.defaultHeaders,
    params: options.defaultParams,
    // TODO: Add if necessary
    // paramsSerializer: {
    //   serialize: p => qs.stringify(p, { indices: false }),
    // },
  })

  if (options.intercept !== false) {
    client.interceptors.response.use(handleResponse, handleResponseRejection)
  }

  return client
}

const core = createAxiosClient(process.env.NEXT_PUBLIC_CORE_API_URL, {
  defaultHeaders,
})

const card = createAxiosClient(process.env.NEXT_PUBLIC_CARD_API_URL, {
  defaultHeaders,
})

const media = createAxiosClient(process.env.NEXT_PUBLIC_MEDIA_API_URL, {
  defaultHeaders,
})

const user = createAxiosClient(process.env.NEXT_PUBLIC_USER_API_URL, {
  defaultHeaders,
})

const archive = createAxiosClient(process.env.NEXT_PUBLIC_ARCHIVE_API_URL, {
  defaultHeaders,
})

const google = createAxiosClient('https://maps.googleapis.com', {
  intercept: false,
  defaultParams: {
    key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  },
})

const wildcatAPIClientsMap: Record<
  Exclude<APIType, APIType.Google>,
  AxiosInstance
> = {
  [APIType.Core]: core,
  [APIType.Card]: card,
  [APIType.Media]: media,
  [APIType.User]: user,
  [APIType.Archive]: archive,
}

const wildcatAPIClients = Object.values(wildcatAPIClientsMap)

function setApiClientsHeader(
  headerName: string,
  value: string,
  clients: AxiosInstance[] = wildcatAPIClients,
) {
  clients.forEach(apiClient => {
    // eslint-disable-next-line no-param-reassign
    apiClient.defaults.headers[headerName] = value
  })
}

function getApiInstance(type: APIType) {
  switch (type) {
    case APIType.Google:
      return google

    case APIType.Media:
      return media

    case APIType.Card:
      return card

    case APIType.User:
      return user

    case APIType.Archive:
      return archive

    case APIType.Core:
    default:
      return core
  }
}

export function setAcceptLanguage(locale: string) {
  setApiClientsHeader('Accept-Language', getLocaleTag(locale))
}

export function setAuthHeader(bearerToken: string) {
  setApiClientsHeader('Authorization', `Bearer ${bearerToken}`)
}

function getEndpoint(endpoint: string, requestOptions: RequestOptions) {
  switch (requestOptions.apiType) {
    case APIType.Google:
      return endpoint
    default:
      return getEndpointWithVersion(endpoint, requestOptions.version)
  }
}

interface IRequest {
  put: APIPutRequest
  get: APIGetRequest
  post: APIPostRequest
  delete: APIDeleteRequest
  patch: APIPatchRequest
}

type RequestOptions = {
  cancelable: boolean
  external: boolean
  apiType: APIType
  version: number
}

class Request implements IRequest {
  private client: AxiosInstance

  private isFetching: boolean

  private abortController?: AbortController

  private apiType: APIType

  static async buildAuthHeader(): Promise<APIHeaders> {
    if (typeof window === 'undefined') return {}

    const authToken = await firebaseAuth.currentUser?.getIdToken?.()

    if (authToken) {
      return {
        Authorization: `Bearer ${authToken}`,
      }
    }

    // Wait for initial token
    return new Promise<{ Authorization: string }>(resolve => {
      const unsubscribe = firebaseAuth.onIdTokenChanged(
        async (u: User | null) => {
          const token = await u?.getIdToken()
          if (token) {
            resolve({
              Authorization: `Bearer ${token}`,
            })

            unsubscribe()
          }
        },
      )
    })
  }

  static defaultOptions: RequestOptions = {
    cancelable: false,
    external: false,
    apiType: APIType.Core,
    version: 1.0,
  }

  constructor(
    private endpoint: string,
    options: Partial<RequestOptions> = Request.defaultOptions,
  ) {
    const mergedOptions = { ...Request.defaultOptions, ...options }
    this.apiType = mergedOptions.apiType

    this.client = getApiInstance(mergedOptions.apiType)
    this.endpoint = getEndpoint(endpoint, mergedOptions)
    this.isFetching = false

    if (mergedOptions.cancelable) {
      this.abortController = new AbortController()
    }
  }

  /**
   * Get the default headers (auth token) and add any additional headers if needed
   * @param {Object} additionalHeaders - An object of additional headers
   * @returns {Object} - Headers object
   */
  getHeaders = async (additionalHeaders?: APIHeaders): Promise<APIHeaders> => {
    switch (this.apiType) {
      case APIType.Google:
        return additionalHeaders || {}

      default: {
        const headers = {
          'x-correlation-id': v4(),
          ...(await Request.buildAuthHeader()),
        }

        if (additionalHeaders) {
          return { ...headers, ...additionalHeaders }
        }

        return headers
      }
    }
  }

  reset = () => {
    if (this.isFetching && this.abortController) {
      this.abortController.abort()
      this.abortController = new AbortController()
    }

    this.isFetching = true
  }

  get = async <ReturnType = any>(
    queryParams?: APIParams,
    headers?: APIHeaders,
    axiosConfig: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<ReturnType>> => {
    this.reset()

    const { url, params } = getUrlAndParams(this.endpoint, queryParams)

    try {
      const result = await this.client.get(url, {
        params,
        headers: await this.getHeaders(headers),
        signal: this.abortController?.signal,
        endpoint: this.endpoint,
        ...axiosConfig,
      })
      return result
    } finally {
      this.isFetching = false
    }
  }

  put = async <ReturnType = any>(
    data?: APIData,
    queryParams?: APIParams,
    headers?: APIHeaders,
    axiosConfig: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<ReturnType>> => {
    this.reset()

    const { url, params } = getUrlAndParams(this.endpoint, queryParams)

    try {
      const result = await this.client.put(url, data, {
        params,
        headers: await this.getHeaders(headers),
        signal: this.abortController?.signal,
        endpoint: this.endpoint,
        ...axiosConfig,
      })

      return result
    } finally {
      this.isFetching = false
    }
  }

  post = async <ReturnType = any>(
    data?: APIData,
    queryParams?: APIParams,
    headers?: APIHeaders,
    axiosConfig: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<ReturnType>> => {
    this.reset()

    const { url, params } = getUrlAndParams(this.endpoint, queryParams)

    try {
      const result = await this.client.post(url, data, {
        params,
        headers: await this.getHeaders(headers),
        signal: this.abortController?.signal,
        endpoint: this.endpoint,
        ...axiosConfig,
      })

      return result
    } finally {
      this.isFetching = false
    }
  }

  delete = async <ReturnType = any>(
    data?: APIData,
    queryParams?: APIParams,
    headers?: APIHeaders,
    axiosConfig: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<ReturnType>> => {
    this.reset()

    const { url, params } = getUrlAndParams(this.endpoint, queryParams)

    try {
      const result = await this.client.delete(url, {
        data,
        params,
        headers: await this.getHeaders(headers),
        signal: this.abortController?.signal,
        endpoint: this.endpoint,
        ...axiosConfig,
      })
      return result
    } finally {
      this.isFetching = false
    }
  }

  patch = async <ReturnType = any>(
    data?: APIData,
    queryParams?: APIParams,
    headers?: APIHeaders,
    axiosConfig: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<ReturnType>> => {
    this.reset()

    const { url, params } = getUrlAndParams(this.endpoint, queryParams)

    try {
      const result = await this.client.patch(url, data, {
        params,
        headers: await this.getHeaders(headers),
        signal: this.abortController?.signal,
        endpoint: this.endpoint,
        ...axiosConfig,
      })

      return result
    } finally {
      this.isFetching = false
    }
  }
}

export default Request
