import { CheckDetails } from '@ancon/wildcat-types'

import { PayAndGoSession } from '../types'

import findPayAndGoMetadataBySessionId from './findPayAndGoMetadataBySessionId'

function updatePayAndGoSession(
  check: CheckDetails,
  sessionId: string,
  updater: (prev: PayAndGoSession | null) => PayAndGoSession,
): PayAndGoSession {
  const meta = findPayAndGoMetadataBySessionId(check, sessionId)

  if (meta) {
    const session = {
      sessionId: meta.metaData.sessionId,
      modified: meta.metaData.modified,
      selectedItems: meta.metaData.selectedItems,
    }

    return updater(session)
  }

  return updater(null)
}

export default updatePayAndGoSession
