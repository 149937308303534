import { CheckDetails } from '@ancon/wildcat-types'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'

import { PayAndGoCartSummary } from '../types'
import getFormattedQuantity from '../../app/utils/getFormattedQuantity'

function getDiscountAmount(check: CheckDetails): string | null {
  if (check.subTotalDiscountInclTax.amount > 0) {
    return `-${getFormattedCurrency(
      check.subTotalDiscountInclTax.amount,
      check.subTotalDiscountInclTax.currency,
      { trimTrailingZeros: false },
    )}`
  }

  return null
}

function getPayAndGoCartSummaryFromCheckDetails(
  check: CheckDetails,
): PayAndGoCartSummary {
  const discountAmount = getDiscountAmount(check)
  const subtotalExclTaxAmount = getFormattedCurrency(
    check.subtotalExclTax.amount,
    check.subtotalExclTax.currency,
    { trimTrailingZeros: false },
  )
  const subtotalInclTaxAmount = getFormattedCurrency(
    check.subtotalInclTax.amount,
    check.subtotalInclTax.currency,
    { trimTrailingZeros: false },
  )
  const taxAmount = getFormattedCurrency(
    check.taxAmount.amount,
    check.taxAmount.currency,
    { trimTrailingZeros: false },
  )
  const totalAmount = getFormattedCurrency(
    check.totalAmount.amount,
    check.totalAmount.currency,
    { trimTrailingZeros: false },
  )

  const totalItemsCount = getFormattedQuantity(check.items.length)

  return {
    discountAmount,
    subtotalExclTaxAmount,
    subtotalInclTaxAmount,
    taxAmount,
    totalAmount,
    totalItemsCount,
  }
}

export default getPayAndGoCartSummaryFromCheckDetails
