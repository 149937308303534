import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CheckUpdatedEvent } from '@ancon/wildcat-types'

import { CheckReducerState } from '../types'

import { checkDetailsEntityAdapter } from './checkEntityAdapters'
import {
  checkAdyenAdditionalDetails,
  checkCreateAdyenPayment,
  fetchCheckAdyenPaymentMethods,
  fetchCheckDetails,
  splitCheckItems,
} from './checkThunks'

const checkInitialState: CheckReducerState = {
  details: checkDetailsEntityAdapter.getInitialState(),

  currentCheckId: null,
  currentCheckOutlet: null,

  checkAdyenPaymentAttemptCount: 1,
  isFetchingCheckAdyenPaymentMethods: false,
  checkAdyenPaymentMethods: null,
  checkAdyenPaymentMethodsError: null,
}

const internalCheckDetailsAdapterSelectors =
  checkDetailsEntityAdapter.getSelectors()

const checkSlice = createSlice({
  name: 'check',
  initialState: checkInitialState,
  reducers: {
    setSelectedCheckId(state, action: PayloadAction<string>) {
      state.currentCheckId = action.payload
    },
    emitCheckUpdatedEvent(state, action: PayloadAction<CheckUpdatedEvent>) {
      checkDetailsEntityAdapter.updateOne(state.details, {
        id: action.payload.checkId,
        changes: action.payload.check,
      })
    },
    checkRetryAdyenPayment(state) {
      state.checkAdyenPaymentAttemptCount += 1
    },
    checkClearAdyenPaymentAttempts(state) {
      state.checkAdyenPaymentAttemptCount = 1
    },
    checkClearSelectedCheckId(state) {
      state.currentCheckId = null
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCheckDetails.fulfilled, (state, action) => {
      const { check, outlet } = action.payload
      checkDetailsEntityAdapter.upsertOne(state.details, check)
      state.currentCheckId = check.id
      state.currentCheckOutlet = outlet
    })
    builder.addCase(splitCheckItems.fulfilled, (state, action) => {
      const { checkId } = action.meta.arg
      const { created, updated } = action.payload

      const check = internalCheckDetailsAdapterSelectors.selectById(
        state.details,
        checkId,
      )

      if (check != null) {
        const newItems = check.items
          .map(item => {
            const updatedItem = updated.find(i => i.id === item.id)

            if (updatedItem != null) {
              return updatedItem
            }

            return item
          })
          .concat(created)

        checkDetailsEntityAdapter.updateOne(state.details, {
          id: checkId,
          changes: {
            items: newItems,
          },
        })
      }
    })
    builder.addCase(fetchCheckAdyenPaymentMethods.pending, state => {
      state.isFetchingCheckAdyenPaymentMethods = true
      state.checkAdyenPaymentMethodsError = null
    })
    builder.addCase(
      fetchCheckAdyenPaymentMethods.fulfilled,
      (state, action) => {
        const { paymentProviderData } = action.payload
        state.isFetchingCheckAdyenPaymentMethods = false
        state.checkAdyenPaymentMethods = paymentProviderData
      },
    )
    builder.addCase(fetchCheckAdyenPaymentMethods.rejected, (state, action) => {
      state.isFetchingCheckAdyenPaymentMethods = false
      state.checkAdyenPaymentMethodsError = action.error
    })
    builder.addCase(checkCreateAdyenPayment.fulfilled, (state, action) => {
      const { check } = action.payload
      checkDetailsEntityAdapter.updateOne(state.details, {
        id: check.id,
        changes: check,
      })
    })
    builder.addCase(checkAdyenAdditionalDetails.fulfilled, (state, action) => {
      if (action.payload) {
        const { check } = action.payload
        checkDetailsEntityAdapter.updateOne(state.details, {
          id: check.id,
          changes: check,
        })
      }
    })
  },
})

const checkReducer = checkSlice.reducer

export const {
  setSelectedCheckId,
  emitCheckUpdatedEvent,
  checkRetryAdyenPayment,
  checkClearAdyenPaymentAttempts,
  checkClearSelectedCheckId,
} = checkSlice.actions

export default checkReducer
