import {
  CheckDetails,
  CheckDetailsOutletExtent,
  CheckItem,
  CheckStatus,
  MetadataType,
  PaymentMethodType,
  PaymentType,
} from '@ancon/wildcat-types'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import api from '../../../api'
import {
  AdyenPaymentMethodsResponse,
  AdyenPaymentResponse,
} from '../../adyen/types'
import { appLanguageSelector } from '../../app/store/appSelectors'
import getLocaleTag from '../../app/utils/getLocalTag'
import archiveLogger from '../../../utils/archiveLogger'
import serializeError from '../../../store/utils/serializeError'
import { CheckClientHeader } from '../constants'

import {
  checkCurrentCheckIdSelector,
  checkCurrentCheckOutletIdSelector,
} from './checkSelectors'

export const fetchCheckDetails = createAppAsyncThunk<
  { check: CheckDetails; outlet: CheckDetailsOutletExtent },
  {
    correlationId?: string
    virtualPOSClientId?: string
    outletId: string
    checkId: string
  }
>(
  'check/fetchCheckDetails',
  async ({ correlationId, virtualPOSClientId, outletId, checkId }) => {
    const response = await api.core.checks.get.details(
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const splitCheckItems = createAppAsyncThunk<
  {
    created: CheckItem[]
    updated: CheckItem[]
  },
  {
    correlationId?: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    parts: number
    checkItemIds: string[]
  }
>(
  'check/splitCheckItems',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    parts,
    checkItemIds,
  }) => {
    const response = await api.core.items.put.split(
      {
        parts,
        checkItemIds,
      },
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const updateCheckMetadata = createAppAsyncThunk<
  { check: CheckDetails },
  {
    correlationId?: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    type: MetadataType
    reference: string
    metadata: string
  }
>(
  'check/updateCheckMetadata',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    type,
    reference,
    metadata,
  }) => {
    const response = await api.core.checks.put.metadata(
      {
        type,
        reference,
        metadata,
      },
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const extractCertainCheckItems = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    mergeBackAt: string
    extractedCheck: CheckDetails
  },
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    extractCheckItems: Record<string, number>
  }
>(
  'check/extractCertainCheckItems',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    extractCheckItems,
  }) => {
    const response = await api.core.checks.put.extractCertainItems(
      {
        extractCheckItems,
      },
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const extractPartialCheck = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    extractedCheck: CheckDetails
  },
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    parts: number
    extractParts: number
  }
>(
  'check/extractPartialCheck',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    parts,
    extractParts,
  }) => {
    const response = await api.core.checks.put.extractPartial(
      {
        parts,
        extractParts,
      },
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const fetchCheckStatus = createAppAsyncThunk<
  { status: CheckStatus },
  {
    correlationId?: string
    outletId: string
    checkId: string
  }
>(
  'check/fetchCheckStatus',
  async ({ correlationId, outletId, checkId }) => {
    const response = await api.core.checks.get.status(
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
      },
    )

    return {
      status: response.data.status,
    }
  },
  { serializeError },
)

export const fetchCheckAdyenPaymentMethods = createAppAsyncThunk<
  {
    paymentProviderData: AdyenPaymentMethodsResponse
    useBetaOnline: boolean
  },
  {
    correlationId?: string
    virtualPOSClientId: string
  }
>(
  'check/fetchCheckAdyenPaymentMethods',
  async ({ correlationId, virtualPOSClientId }, { getState }) => {
    const language = appLanguageSelector(getState())

    const outletId = checkCurrentCheckOutletIdSelector(getState())!
    const checkId = checkCurrentCheckIdSelector(getState())!

    const response = await api.core.checks.get.onlinePaymentMethods(
      {
        outletId,
        checkId,
        methodType: PaymentMethodType.Adyen,
        paymentProviderData: JSON.stringify({
          channel: 'pos',
          shopperLocale: getLocaleTag(language).replace('-', '_'),
        }),
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const checkCreateAdyenPayment = createAppAsyncThunk<
  { check: CheckDetails; paymentProviderData: AdyenPaymentResponse },
  {
    data: any
    correlationId: string
    virtualPOSClientId: string
  }
>(
  'check/checkCreateAdyenPayment',
  async ({ correlationId, virtualPOSClientId, data }, { getState }) => {
    const outletId = checkCurrentCheckOutletIdSelector(getState())!
    const checkId = checkCurrentCheckIdSelector(getState())!

    // Sent to archive service
    const debugInfo = {
      CheckId: checkId,
      OutletId: outletId,
      CorrelationId: correlationId,
      Method: 'Adyen Checkout',
    }

    archiveLogger.debug('Payment started', debugInfo)

    const response = await api.core.checks.post.createOnlinePayment(
      {
        paymentProviderData: JSON.stringify(data),
        methodType: PaymentMethodType.Adyen,
        paymentType: PaymentType.Full, // TODO: Implement partial payments
        // tipAmount: 0, // TODO: Implement tipping
      },
      {
        outletId,
        checkId,
      },
      {
        'x-channel': 'pos',
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const checkAdyenAdditionalDetails = createAppAsyncThunk<
  { check: CheckDetails; paymentProviderData: AdyenPaymentResponse } | null,
  {
    correlationId: string
    virtualPOSClientId: string
    data: any
    outletId: string
    checkId: string
  }
>(
  'check/checkAdyenAdditionalDetails',
  async ({ correlationId, virtualPOSClientId, data, outletId, checkId }) => {
    const response = await api.core.checks.post.onlinePaymentDetails(
      {
        paymentProviderData: JSON.stringify(data),
      },
      {
        outletId,
        checkId,
      },
      {
        'x-channel': 'pos',
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    // Bug: Core can respond with 423 and no data
    return response?.data ?? null
  },
  { serializeError },
)
