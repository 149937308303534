import moment from 'moment'

import { PayAndGoSession } from '../types'

function isPayAndGoSessionMetadata(
  value: any,
): value is { metaData: PayAndGoSession } {
  if (typeof value.metaData?.sessionId !== 'string') {
    return false
  }

  if (typeof value.metaData.modified !== 'number') {
    return false
  }

  if (
    typeof value.metaData.selectedItems !== 'object' ||
    !Array.isArray(value.metaData.selectedItems)
  ) {
    return false
  }

  const modified = moment(value.metaData.modified)

  if (!modified.isValid()) {
    return false
  }

  // Add more checks if needed

  return true
}

export default isPayAndGoSessionMetadata
