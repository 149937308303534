import {
  CheckDetails,
  OrderFormat,
  OutletDetails,
  SaleGroupItem,
} from '@ancon/wildcat-types'
import { EntityState } from '@reduxjs/toolkit'

export type PayAndGoGroupParams = {
  outletId: string
  groupId: string
}

export type PayAndGoPaymentParams = {
  outletId: string
  checkId: string
  clientId?: string
}

export enum PayAndGoPayMode {
  Full = 'full',
  SelectItems = 'selectItems',
  SplitEqually = 'splitEqually',
  CertainAmount = 'certainAmount',
}

export enum PayAndGoPayModeModalView {
  None = 'none',
  ChoosePayMode = 'choosePayMode',
  SplitEquallyNew = 'splitEquallyNew',
  CertainAmount = 'certainAmount',
}

export type PayAndGoReducerState = {
  virtualPOSClientId: string | null

  groupParams: PayAndGoGroupParams | null
  paymentParams: PayAndGoPaymentParams | null

  sessionId: string | null

  originalCheckDetails: CheckDetails | null
  /** Extracted or original check depending on whether you pay in full or split out/extracted */
  ownCheckDetails: CheckDetails | null
  /** Datetime string for the point at which the check is expired */
  mergeBackAt: string | null

  outletDetails: OutletDetails | null

  payMode: PayAndGoPayMode
  payModeModalView: PayAndGoPayModeModalView

  /** Own session metadata item selections */
  selectedItem: EntityState<PayAndGoSelectedItem>

  unpaidItem: EntityState<SaleGroupItem>
  ongoingItem: EntityState<SaleGroupItem>
  paidItem: EntityState<SaleGroupItem>

  splitItemModal: PayAndGoSplitItemModalState

  /** Used to track our own requests so that we can ignore our own events */
  requestIds: string[]
}

export type PayAndGoSplitItemModalState = {
  isOpen: boolean
  itemId: string | null
}

export type PayAndGoSelectedItem = {
  ids: string[]
  selectedQuantity: number
  parentId?: string | null
}

export type PayAndGoReservedItem = {
  ids: string[]
  reservedQuantity: number
}

export enum SubItemSelectionState {
  None,
  Some,
  All,
}

export type PayAndGoCartSummary = {
  discountAmount: string | null
  subtotalExclTaxAmount: string | null
  subtotalInclTaxAmount: string | null
  taxAmount: string | null
  totalAmount: string
  totalItemsCount: string
}

export type PayAndGoDiscount = {
  // TODO: TBD
  discountInclTaxAmount: string
}

export type PayAndGoOrderInfo = {
  orderNumber: string
  orderTime: string
  orderFormat: OrderFormat
}

export type PayAndGoSession = {
  sessionId: string
  modified: number
  selectedItems: PayAndGoSelectedItem[]
}

export enum PayAndGoCartMode {
  /** Use selected/available items for cart */
  ClientCalculated,
  /** Use check details for cart */
  ServerCalculated,
}
