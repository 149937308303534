import { EntityId, createSelector } from '@reduxjs/toolkit'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'
import moment from 'moment'

import { RootState } from '../../../store/types'
import {
  PayAndGoCartSummary,
  PayAndGoDiscount,
  PayAndGoOrderInfo,
  PayAndGoPayMode,
  PayAndGoReducerState,
  SubItemSelectionState,
} from '../types'
import getPayAndGoCartSummaryFromCheckDetails from '../utils/getPayAndGoCartSummaryFromCheckDetails'
import getCalculatedPayAndGoCartSummary from '../utils/getCalculatedPayAndGoCartSummary'
import findOthersRecentPayAndGoMetadata from '../utils/findOthersRecentPayAndGoMetadata'

import {
  payAndGoSelectedItemEntityAdapter,
  payAndGoPaidItemEntityAdapter,
  payAndGoUnpaidItemEntityAdapter,
} from './payAndGoEntityAdapters'

function payAndGoStateSelector<K extends keyof PayAndGoReducerState>(
  state: RootState,
  key: K,
) {
  return state.payAndGo[key]
}

// Virtual POS

export const payAndGoVirtualPOSClientIdSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'virtualPOSClientId')

// Unpaid items

const unpaidItemAdapterSelectors = payAndGoUnpaidItemEntityAdapter.getSelectors(
  (state: RootState) => payAndGoStateSelector(state, 'unpaidItem'),
)

const payAndGoUnpaidItemByIdSelector = unpaidItemAdapterSelectors.selectById

export const payAndGoUnpaidItemsSelector = unpaidItemAdapterSelectors.selectAll

// Selected items

const selectedItemAdapterSelectors =
  payAndGoSelectedItemEntityAdapter.getSelectors((state: RootState) =>
    payAndGoStateSelector(state, 'selectedItem'),
  )

export const payAndGoSelectedItemByIdSelector =
  selectedItemAdapterSelectors.selectById

export const payAndGoSelectedItemQuantityByIdSelector = (
  state: RootState,
  id: string,
): number => payAndGoSelectedItemByIdSelector(state, id)?.selectedQuantity ?? 0

export const payAndGoSelectedItemsSelector =
  selectedItemAdapterSelectors.selectAll

// Paid items

const paidItemAdapterSelectors = payAndGoPaidItemEntityAdapter.getSelectors(
  (state: RootState) => payAndGoStateSelector(state, 'paidItem'),
)

export const payAndGoPaidItemsSelector = paidItemAdapterSelectors.selectAll

// Original check

export const payAndGoOriginalCheckDetailsSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'originalCheckDetails')

export const payAndGoGroupParamsSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'groupParams')

export const payAndGoPaymentParamsSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'paymentParams')

export const payAndGoOutletDetailsSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'outletDetails')

export const payAndGoPayModeSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'payMode')

export const payAndGoPayModeModalViewSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'payModeModalView')

// Own check details (the one used on payment & completed page)

export const payAndGoOwnCheckDetailsSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'ownCheckDetails')

export const payAndGoOwnCheckItemsSelector = createSelector(
  [payAndGoOwnCheckDetailsSelector],
  check => check?.items ?? [],
)

export const payAndGoOwnCheckStatusSelector = createSelector(
  [payAndGoOwnCheckDetailsSelector],
  check => check?.status,
)

export const payAndGoMergeBackAtSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'mergeBackAt')

export const payAndGoAvailableSubItemSelectionStateSelector = createSelector(
  [payAndGoUnpaidItemByIdSelector, selectedItemAdapterSelectors.selectAll],
  (item, selectedItems): SubItemSelectionState => {
    if (!item) {
      return SubItemSelectionState.None
    }

    const selectedSubItems = selectedItems.filter(
      selectedItem =>
        selectedItem.parentId != null &&
        item.ids.includes(selectedItem.parentId),
    )

    if (selectedSubItems.length === 0) {
      return SubItemSelectionState.None
    }

    if (item.subItems && selectedSubItems.length === item.subItems.length) {
      return SubItemSelectionState.All
    }

    return SubItemSelectionState.Some
  },
)

// Cart summary

export const payAndServerCalculatedCartSummarySelector = createSelector(
  [payAndGoOwnCheckDetailsSelector],
  (check): PayAndGoCartSummary | null => {
    if (check) {
      return getPayAndGoCartSummaryFromCheckDetails(check)
    }

    return null
  },
)

export const payAndGoClientCalculatedCartSummarySelector = createSelector(
  [
    payAndGoPayModeSelector,
    payAndGoOriginalCheckDetailsSelector,
    payAndGoUnpaidItemsSelector,
    payAndGoSelectedItemsSelector,
  ],
  (
    payMode,
    originalCheck,
    unpaidItems,
    selectedItems,
  ): PayAndGoCartSummary | null => {
    if (!originalCheck) {
      return null
    }

    switch (payMode) {
      case PayAndGoPayMode.SelectItems:
        return getCalculatedPayAndGoCartSummary(
          originalCheck,
          selectedItems,
          'selectedQuantity',
        )

      default:
        return getCalculatedPayAndGoCartSummary(
          originalCheck,
          unpaidItems,
          'quantity',
        )
    }
  },
)

export const payAndGoServerCalculatedCartHasItemsSelector = (
  state: RootState,
) => payAndGoOwnCheckItemsSelector(state).length > 0

export const payAndGoClientCalculatedCartHasItemsSelector = (
  state: RootState,
) => {
  switch (payAndGoPayModeSelector(state)) {
    case PayAndGoPayMode.SelectItems:
      return payAndGoSelectedItemsSelector(state).length > 0

    default:
      return payAndGoUnpaidItemsSelector(state).length > 0
  }
}

// TODO: Replace this selector when API is ready
export const payAndGoDiscountSelector = (
  state: RootState,
): PayAndGoDiscount | null => {
  const check = payAndGoOriginalCheckDetailsSelector(state)

  // TODO: Replace when API is ready
  if (check != null && check.subTotalDiscountInclTax.amount > 0) {
    return {
      discountInclTaxAmount: getFormattedCurrency(
        check.subTotalDiscountInclTax.amount,
        check.subTotalDiscountInclTax.currency,
      ),
    }
  }

  return null
}

export const payAndGoOrderInfoSelector = createSelector(
  [payAndGoOriginalCheckDetailsSelector],
  (check): PayAndGoOrderInfo | null => {
    if (check) {
      return {
        orderNumber: check.incrementalNumbers.ticketNumber.toString(),
        orderTime: moment(check.created).format('YYYY-MM-DD HH:mm'),
        orderFormat: check.orderFormat,
      }
    }

    return null
  },
)

// Split item modal

export const payAndGoIsSplitItemModalOpenSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'splitItemModal').isOpen

export const payAndGoSplitItemModalItemIdSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'splitItemModal').itemId

export const payAndGoSplitItemAvailableQuantitySelector = (
  state: RootState,
) => {
  const itemId = payAndGoSplitItemModalItemIdSelector(state)

  const item = payAndGoUnpaidItemByIdSelector(state, itemId as EntityId)

  return item?.quantity ?? 0
}

// Session

export const payAndGoSessionIdSelector = (state: RootState) =>
  payAndGoStateSelector(state, 'sessionId')

export const payAndGoReservedItemQuantityByIdSelector = (
  state: RootState,
  id: string,
): number => {
  const originalCheck = payAndGoOriginalCheckDetailsSelector(state)
  const sessionId = payAndGoSessionIdSelector(state)

  if (!originalCheck || !sessionId) {
    return 0
  }

  const othersMetadata = findOthersRecentPayAndGoMetadata(
    originalCheck,
    sessionId,
  )

  const reservedQty =
    othersMetadata?.reduce((acc, meta) => {
      const otherSelectedItem = meta.metaData?.selectedItems.find(i =>
        i.ids.includes(id),
      )

      if (otherSelectedItem) {
        return acc + otherSelectedItem.selectedQuantity
      }

      return acc
    }, 0) ?? 0

  return reservedQty
}

// Receipt on completed page

export const payAndGoReceiptInfoSelector = createSelector(
  [payAndGoOwnCheckDetailsSelector, payAndGoOutletDetailsSelector],
  (check, outlet) => {
    if (!check || !outlet) {
      return null
    }

    return {
      orderId: check.orderId,
      orderNumber: check.incrementalNumbers.ticketNumber,
      outletName: outlet.name,
    }
  },
)
