import { createSelector } from '@reduxjs/toolkit'
import {
  AttendeePreOrderStatus,
  CheckoutCancelInfo,
  CheckoutStatus,
  OrderFormatOption,
  PreOrderAttendee,
} from '@ancon/wildcat-types'

import { RootState } from '../../../store/types'
import { OrderSummaryStatusState } from '../../checkout/types'
import isCheckoutItemReady from '../../checkout/utils/isCheckoutItemReady'

import {
  preOrderUserIdSelector,
  preOrderUserIsHostSelector,
} from './preOrderSelectors'

// Simple selectors
export const preOrderCheckoutSummarySelector = (state: RootState) =>
  state.preOrder.preOrderCheckoutSummary

export const preOrderCheckoutSummaryFetchPendingSelector = (state: RootState) =>
  state.preOrder.preOrderCheckoutSummaryFetchPending

export const preOrderSummaryOutletListItemSelector = (state: RootState) =>
  state.preOrder.preOrderSummaryOutletListItem

export const hasPreOrderCheckoutSummarySelector = (state: RootState) =>
  !!preOrderCheckoutSummarySelector(state)

export const preOrderSummaryCheckoutIdSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.id

export const preOrderCheckoutSummaryPreOrderNameSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.preOrderName

export const preOrderCheckoutSummaryOrderFormatSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.orderFormat

export const preOrderCheckoutSummaryOrderTypeSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.orderType

export const preOrderCheckoutSummaryTicketNumberSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.ticketNumber

export const preOrderCheckoutSummaryOutletSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.outlet

export const preOrderCheckoutSummaryOutletIdSelector = (state: RootState) =>
  preOrderCheckoutSummaryOutletSelector(state)?.id

export const preOrderCheckoutSummaryOutletNameSelector = (state: RootState) =>
  preOrderCheckoutSummaryOutletSelector(state)?.name

export const preOrderCheckoutSummaryServiceTimeSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.serviceTime

export const preOrderCheckoutSummaryCompanySelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.company

export const preOrderSummaryAttendeeSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.attendee

export const preOrderCheckoutSummaryAttendeesSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.attendees ?? []

export const preOrderSummaryMembersAttendanceSelector = (state: RootState) =>
  preOrderCheckoutSummaryAttendeesSelector(state).length

export const preOrderSummaryItemsSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.items ?? []

export const preOrderSummaryTicketIdSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.tickets?.[0]?.id

export const preOrderSummaryCheckoutStatusSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.checkoutStatus ||
  CheckoutStatus.Created

export const preOrderCheckoutSummaryPreparationTimeSelector = (
  state: RootState,
) => preOrderCheckoutSummarySelector(state)?.tickets?.[0]?.preparationTime

export const preOrderSummaryPreOrderCancelInfoSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.preOrderCancelInfo

export const preOrderSummaryPreOrderStatusSelector = (state: RootState) =>
  preOrderCheckoutSummarySelector(state)?.preOrderStatus

export const preOrderSummaryOutletPartiallyPrepareEnabledSelector = (
  state: RootState,
) =>
  !!preOrderSummaryOutletListItemSelector(state)?.enablePartiallyPrepareOrders

export const preOrderCheckoutSummaryAuthorizedAtSelectorSelector = (
  state: RootState,
) => preOrderCheckoutSummarySelector(state)?.authorizedAt

// Combined selectors

export const preOrderCheckoutSummaryAttendeeByIdSelector = (
  state: RootState,
  attendeeId?: string | null,
) => {
  const preOrderCheckout = preOrderCheckoutSummarySelector(state)

  if (!attendeeId || !preOrderCheckout) {
    return undefined
  }

  const { attendee, attendees } = preOrderCheckout

  if (attendee && attendee.id === attendeeId) {
    return attendee
  }

  return (attendees ?? []).find(a => a.id === attendeeId)
}

export const preOrderCheckoutSummaryUserAttendeeSelector = (
  state: RootState,
) => {
  const preOrderUserId = preOrderUserIdSelector(state)
  const preOrderAttendee = preOrderCheckoutSummaryAttendeeByIdSelector(
    state,
    preOrderUserId,
  )
  return preOrderAttendee
}

export const preOrderCheckoutSummaryUserItemsSelector = createSelector(
  preOrderCheckoutSummaryUserAttendeeSelector,
  attendee => attendee?.items ?? [],
)

export const preOrderCheckoutSummaryUserConfirmationStatusSelector =
  createSelector(
    preOrderCheckoutSummaryUserAttendeeSelector,
    attendee => attendee?.status ?? AttendeePreOrderStatus.Pending,
  )

export const preOrderSummaryCheckoutItemsSelector = createSelector(
  [preOrderSummaryItemsSelector, preOrderSummaryAttendeeSelector],
  (items, attendee) => attendee?.items ?? items,
)

export const preOrderCheckoutSummaryDeliveryTrackingURLSelector =
  createSelector(
    preOrderCheckoutSummarySelector,
    checkout => checkout?.deliveryMetaData?.trackingUrl,
  )

export const preOrderCheckoutSummaryDeliveryStatusSelector = createSelector(
  preOrderCheckoutSummarySelector,
  checkout => checkout?.deliveryStatus,
)

export const preOrderCheckoutSummaryDeliveryProviderSelector = createSelector(
  preOrderCheckoutSummarySelector,
  checkout => checkout?.deliveryProvider,
)

export const preOrderSummaryStatusStateSelector = createSelector(
  [
    preOrderSummaryCheckoutIdSelector,
    preOrderCheckoutSummaryOrderFormatSelector,
    preOrderSummaryCheckoutItemsSelector,
    preOrderCheckoutSummaryPreparationTimeSelector,
    preOrderCheckoutSummaryServiceTimeSelector,
    preOrderSummaryCheckoutStatusSelector,
    preOrderSummaryPreOrderStatusSelector,
    preOrderUserIsHostSelector,
    preOrderCheckoutSummaryUserConfirmationStatusSelector,
    preOrderSummaryPreOrderCancelInfoSelector,
    preOrderSummaryOutletPartiallyPrepareEnabledSelector,
    preOrderCheckoutSummaryDeliveryStatusSelector,
    preOrderCheckoutSummaryDeliveryProviderSelector,
  ],
  (
    preOrderId,
    orderFormat,
    checkoutItems,
    preparationTime,
    serviceTime,
    checkoutStatus,
    preOrderStatus,
    isHost,
    attendeeStatus,
    preOrderCancelInfo,
    enablePartiallyPrepareOrders,
    deliveryStatus,
    deliveryProvider,
  ): OrderSummaryStatusState => ({
    checkout: {
      id: preOrderId!,
      orderFormat: orderFormat!,
      items: checkoutItems,
      deliveryStatus,
      deliveryProvider,
    },
    preparationTime,
    serviceTime,
    checkoutStatus,
    // Pre-orders not supported for table orders
    customersFetchTableOrders: false,
    enablePartiallyPrepareOrders,
    preOrderOptions: {
      isHost,
      preOrderStatus: preOrderStatus!,
      preOrderCancelInfo,
      preOrderAttendeeStatus: attendeeStatus,
    },
  }),
)

export const preOrderCheckoutSummaryCancelInfoSelector = createSelector(
  preOrderCheckoutSummarySelector,
  checkout => {
    const { checkoutStatus, cancelInfo, preOrderCancelInfo } = checkout ?? {}
    if (checkoutStatus === CheckoutStatus.Canceled) {
      return cancelInfo
    }
    return preOrderCancelInfo
      ? ({
          actor: preOrderCancelInfo.cancelActor,
        } as CheckoutCancelInfo)
      : undefined
  },
)

export const preOrderSummaryUseMarkAsPickedUpSelector = createSelector(
  [
    preOrderCheckoutSummaryOrderFormatSelector,
    preOrderSummaryOutletListItemSelector,
  ],
  (preOrderOrderFormat, preOrderOutletListItem) => {
    if (preOrderOrderFormat && preOrderOutletListItem) {
      const { orderFormatSettings } = preOrderOutletListItem

      const orderFormatSetting = orderFormatSettings.find(
        settings => settings.orderFormat === preOrderOrderFormat,
      )

      if (orderFormatSetting) {
        return (
          (OrderFormatOption.UseMarkAsPickedUp & orderFormatSetting.options) ===
          OrderFormatOption.UseMarkAsPickedUp
        )
      }
    }

    return false
  },
)

export const preOrderSummaryReadyItemIdsSelector = createSelector(
  preOrderSummaryCheckoutItemsSelector,
  items =>
    items.reduce<string[]>((acc, { ticketItem }) => {
      if (ticketItem && isCheckoutItemReady({ ticketItem })) {
        acc.push(ticketItem.ticketItemId)
      }
      return acc
    }, []),
)

export const preOrderSummaryItemsReadyAttendeesSelector = createSelector(
  preOrderCheckoutSummaryAttendeesSelector,
  attendees =>
    attendees.reduce<PreOrderAttendee[]>((acc, attendee) => {
      const readyItems = attendee.items.filter(isCheckoutItemReady)

      if (readyItems.length > 0) {
        acc.push({ ...attendee, items: readyItems })
      }

      return acc
    }, []),
)

export const preOrderCheckoutSummaryUserReadyItemsSelector = createSelector(
  preOrderCheckoutSummaryUserItemsSelector,
  items => items.filter(isCheckoutItemReady),
)
