import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'
import { CheckDetails } from '@ancon/wildcat-types'

import { PayAndGoCartSummary } from '../types'
import getFormattedQuantity from '../../app/utils/getFormattedQuantity'

function getCalculatedPayAndGoCartSummary<
  PropName extends keyof Item,
  Item extends { ids: string[] } & Record<PropName, number>,
>(
  originalCheck: CheckDetails,
  items: Item[],
  quantityProp: PropName,
): PayAndGoCartSummary | null {
  const { currency } = originalCheck.totalAmount

  let totalAmount = 0
  let taxAmount = 0
  let subtotalInclTaxAmount = 0
  let subtotalExclTaxAmount = 0

  for (const item of items) {
    const checkItem = originalCheck.items.find(i => item.ids.includes(i.id))

    if (checkItem) {
      const selectedQuantity = item[quantityProp]
      const originalQuantity = checkItem.quantity

      const multiplier = selectedQuantity / originalQuantity

      totalAmount += checkItem.totalPriceInclTax.amount * multiplier

      taxAmount +=
        Math.max(
          0,
          checkItem.totalPriceInclTax.amount -
            checkItem.totalPriceExclTax.amount,
        ) * multiplier

      subtotalInclTaxAmount += checkItem.totalPriceInclTax.amount * multiplier

      subtotalExclTaxAmount += checkItem.totalPriceExclTax.amount * multiplier
    }
  }

  const totalAmountText = getFormattedCurrency(totalAmount, currency, {
    trimTrailingZeros: false,
  })
  const taxAmountText = getFormattedCurrency(taxAmount, currency, {
    trimTrailingZeros: false,
  })
  const subtotalInclTaxAmountText = getFormattedCurrency(
    subtotalInclTaxAmount,
    currency,
    { trimTrailingZeros: false },
  )
  const subtotalExclTaxAmountText = getFormattedCurrency(
    subtotalExclTaxAmount,
    currency,
    { trimTrailingZeros: false },
  )

  return {
    discountAmount: null,
    subtotalExclTaxAmount: subtotalExclTaxAmountText,
    subtotalInclTaxAmount: subtotalInclTaxAmountText,
    taxAmount: taxAmountText,
    totalAmount: totalAmountText,
    totalItemsCount: getFormattedQuantity(items.length),
  }
}

export default getCalculatedPayAndGoCartSummary
