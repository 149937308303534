import { RootState } from '../../../store/types'
import { CheckReducerState } from '../types'

import { checkDetailsEntityAdapter } from './checkEntityAdapters'

function checkSelector<K extends keyof CheckReducerState>(
  state: RootState,
  key: K,
) {
  return state.check[key]
}

// Check details

const checkDetailsAdapterSelectors =
  checkDetailsEntityAdapter.getSelectors<RootState>(
    state => state.check.details,
  )

export const checkDetailsByIdSelector = checkDetailsAdapterSelectors.selectById

export const checkCurrentCheckIdSelector = (state: RootState) =>
  checkSelector(state, 'currentCheckId')

export const checkCurrentCheckOutletSelector = (state: RootState) =>
  checkSelector(state, 'currentCheckOutlet')

export const checkCurrentCheckOutletIdSelector = (
  state: RootState,
): string | null => {
  const outlet = checkCurrentCheckOutletSelector(state)

  return outlet?.id || null
}

// Adyen payment methods

export const checkIsFetchingAdyenPaymentMethodsSelector = (state: RootState) =>
  checkSelector(state, 'isFetchingCheckAdyenPaymentMethods')

export const checkAdyenPaymentMethodsSelector = (state: RootState) =>
  checkSelector(state, 'checkAdyenPaymentMethods')

export const checkAdyenPaymentMethodsErrorSelector = (state: RootState) =>
  checkSelector(state, 'checkAdyenPaymentMethodsError')

export const checkoutHasAdyenPaymentMethodsSelector = (state: RootState) =>
  !!checkAdyenPaymentMethodsSelector(state)

// Adyen drop-in

export const checkAdyenPaymentAttemptCountSelector = (state: RootState) =>
  checkSelector(state, 'checkAdyenPaymentAttemptCount')

export const checkCurrentCheckDetailsSelector = (state: RootState) => {
  const currentCheckId = checkCurrentCheckIdSelector(state)

  if (!currentCheckId) {
    return null
  }

  return checkDetailsByIdSelector(state, currentCheckId) || null
}
