import {
  CheckDetails,
  CheckItem,
  MetadataType,
  OutletDetails,
  SaleGroupItem,
} from '@ancon/wildcat-types'
import getTranslation from 'next-translate/getT'
import { v4 } from 'uuid'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import {
  extractCertainCheckItems,
  extractPartialCheck,
  fetchCheckDetails,
  splitCheckItems,
  updateCheckMetadata,
} from '../../check/store/checkThunks'
import api from '../../../api'
import { appShowError } from '../../app/store/appSlice'
import getTranslatedError from '../../app/utils/getTranslatedError'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { PayAndGoSelectedItem } from '../types'
import updatePayAndGoSession from '../utils/updatePayAndGoSession'

import {
  payAndGoOriginalCheckDetailsSelector,
  payAndGoGroupParamsSelector,
  payAndGoSelectedItemsSelector,
  payAndGoSessionIdSelector,
  payAndGoSplitItemModalItemIdSelector,
  payAndGoVirtualPOSClientIdSelector,
} from './payAndGoSelectors'

export const fetchPayAndGoGroup = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    unpaidItems: SaleGroupItem[]
    ongoingItems: SaleGroupItem[]
    paidItems: SaleGroupItem[]
    virtualPos: { id: string } | null
  },
  {
    outletId: string
    groupId: string
  }
>('payAndGo/fetchPayAndGoGroup', async ({ outletId, groupId }) => {
  const response = await api.core.salesGroups.get.details({
    outletId,
    groupId,
    'extent.virtualPos': true,
  })

  return response.data
})

export const fetchOwnCheckDetails = createAppAsyncThunk<
  CheckDetails,
  {
    outletId: string
    checkId: string
  }
>(
  'payAndGo/fetchOwnCheckDetails',
  async ({ outletId, checkId }, { getState, dispatch }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!

    const data = await dispatch(
      fetchCheckDetails({ virtualPOSClientId, outletId, checkId }),
    ).unwrap()

    return data.check
  },
)

export const fetchPayAndGoOutlet = createAppAsyncThunk<
  OutletDetails,
  { outletId: string }
>('payAndGo/fetchPayAndGoOutlet', async ({ outletId }) => {
  const response = await api.core.outlet.get.details({
    outletId,
  })

  return response.data
})

export const splitPayAndGoCheckItems = createAppAsyncThunk<
  {
    created: CheckItem[]
    updated: CheckItem[]
  } | null,
  {
    parts: number
  }
>(
  'payAndGo/splitPayAndGoCheckItems',
  async ({ parts }, { getState, dispatch }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!

    const params = payAndGoGroupParamsSelector(getState())

    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

    const itemId = payAndGoSplitItemModalItemIdSelector(getState())

    if (!params || !originalCheck || !itemId) {
      return null
    }

    try {
      const data = await dispatch(
        splitCheckItems({
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          parts,
          checkItemIds: [itemId],
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
)

export const updateSelectedPayAndGoItemQuantity = createAppAsyncThunk<
  CheckDetails | null,
  {
    prevItem: PayAndGoSelectedItem | null
    nextItem: PayAndGoSelectedItem | null
  }
>(
  'payAndGo/updateSelectedPayAndGoItemQuantity',
  async (_, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const sessionId = payAndGoSessionIdSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())
    const selectedItems = payAndGoSelectedItemsSelector(getState())

    if (!params || !sessionId || !originalCheck) {
      return null
    }

    const session = updatePayAndGoSession(originalCheck, sessionId, () => ({
      sessionId,
      modified: new Date().valueOf(),
      selectedItems,
    }))

    const data = await dispatch(
      updateCheckMetadata({
        correlationId: requestId,
        virtualPOSClientId,
        outletId: params.outletId,
        checkId: originalCheck.id,
        type: MetadataType.PayAndGoClientItemSelection,
        reference: sessionId,
        metadata: JSON.stringify(session),
      }),
    ).unwrap()

    return data.check
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const clearAllPayAndGoItemSelections =
  createAppAsyncThunk<CheckDetails | null>(
    'payAndGo/clearAllPayAndGoItemSelections',
    async (_, { getState, dispatch, requestId }) => {
      const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
      const params = payAndGoGroupParamsSelector(getState())
      const sessionId = payAndGoSessionIdSelector(getState())
      const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

      if (!params || !sessionId || !originalCheck) {
        return null
      }

      const session = updatePayAndGoSession(originalCheck, sessionId, () => ({
        sessionId,
        modified: new Date().valueOf(),
        selectedItems: [],
      }))

      const data = await dispatch(
        updateCheckMetadata({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          type: MetadataType.PayAndGoClientItemSelection,
          reference: sessionId,
          metadata: JSON.stringify(session),
        }),
      ).unwrap()

      return data.check
    },
    {
      idGenerator() {
        return v4()
      },
    },
  )

export const extractSelectedPayAndGoCheckItems = createAppAsyncThunk<{
  originalCheck: CheckDetails
  mergeBackAt: string
  extractedCheck: CheckDetails
} | null>(
  'payAndGo/extractSelectedPayAndGoCheckItems',
  async (_, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())
    const selectedItems = payAndGoSelectedItemsSelector(getState())

    if (
      !params ||
      !originalCheck ||
      !selectedItems ||
      selectedItems.length < 1
    ) {
      return null
    }

    const extractCheckItems = selectedItems.reduce<Record<string, number>>(
      (acc, item) => {
        const checkItem = originalCheck.items.find(i => item.ids.includes(i.id))

        if (checkItem != null && item.selectedQuantity > 0) {
          acc[checkItem.id] = item.selectedQuantity
        }

        return acc
      },
      {},
    )

    try {
      await dispatch(clearAllPayAndGoItemSelections())

      const data = await dispatch(
        extractCertainCheckItems({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          extractCheckItems,
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const extractPartialPayAndGoCheck = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    extractedCheck: CheckDetails
  } | null,
  { parts: number; extractParts: number }
>(
  'payAndGo/extractPartialPayAndGoCheck',
  async ({ parts, extractParts }, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

    if (!params || !originalCheck) {
      return null
    }

    try {
      const data = await dispatch(
        extractPartialCheck({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          parts,
          extractParts,
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)
